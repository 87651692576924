.userNameContainer {
  display: flex;
}

.firstNameContainer {
  width: 100%;
  margin-right: 20px;
}

.secondNameContainer {
  width: 100%;
}

.emailContainer {
  margin-top: 17px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  padding: 11px 15px !important;

  color: #2a2a2a;
  border: 2px solid #e2eaec;
  border-radius: 5px !important;
  outline: none;
  box-shadow: none;

  font-family: Inter;
  font-size: 16px !important;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
}

.invalidInput {
  border: 2px solid #ad1100 !important;
  background: rgba(173, 17, 0, 0.05);
}

.submitBtnContainer {
  margin: 40px 35px 0;
}

.submitBtn {
  width: 100%;
  padding: 15px;

  cursor: pointer;
  letter-spacing: -0.25px;

  color: #051426;
  border: none;
  border-radius: 4px;
  background: #ffa41d;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 21px;
}

.submitBtnInvalid {
  opacity: 0.5;
}

.optionalFieldsContainer {
  display: flex;

  margin-top: 17px;
}

.phoneNumberContainer {
  width: 100%;
  margin-right: 20px;
}

.timezoneContainer {
  width: 100%;
}

.passwordContainer,
.confirmPasswordContainer {
  width: 100%;
  margin-top: 17px;
}

.showPassword {
  position: absolute;
  right: 0;

  cursor: pointer;

  color: #9da3aa;
}

.passwordField {
  position: relative;
}

@media (max-width: 550px) {
  .userNameContainer {
    flex-direction: column;
  }

  .firstNameContainer {
    margin-right: 0;
  }

  .secondNameContainer {
    margin-top: 17px;
  }

  .submitBtnContainer {
    margin: 34px 0 0;
  }

  .optionalFieldsContainer {
    flex-direction: column;
  }

  .timezoneContainer {
    margin-top: 17px;
  }
}

.hpValue {
  display: none;
}
