/* ==================== Modal Overlay ==================== */
.modalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 0.8);
}

/* ==================== Modal Container ==================== */
.modalContainer {
  box-sizing: border-box;
  max-height: calc(100vh - 150px);
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ==================== Back to Form Button ==================== */
.backToFormButton {
  display: flex;
  align-items: center;
  margin-bottom: 0.65rem;
  cursor: pointer;
  color: white;
  font-size: 0.85rem;
  line-height: 1rem;
  background: none;
  border: none;
}

.backToFormButton > img {
  margin-right: 0.5rem;
}

/* ==================== Modal Content Wrapper ==================== */
.modalContentWrapper {
  display: flex;
  padding: 3.5rem 5rem;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
}

/* ==================== Content Header ==================== */
.modalHeader {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

/* ==================== Content sub Header ==================== */
.modalSubHeader {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

/* ==================== Content Body ==================== */
.modalBody {
  display: flex;
  margin-top: 3.5rem;
  gap: 2rem;
}

/* ==================== Submit Without Paying Button ==================== */
.submitWithoutPayingButton {
  align-self: center;
  margin-top: 44px;
  cursor: pointer;
  text-decoration: underline;
  color: #2c2f32;
  border: none;
  background: none;
  font-size: 0.75rem;
}

/* ==================== Payment Method Card ==================== */
.paymentCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 210px;
  padding: 20px 24px 24px 24px;
  border: 1px solid #e0e1ec;
  border-radius: 3px;
  background: #f1f3f4;
}

.paymentMethodIconContainer {
  height: 5rem;
  display: flex;
  align-items: center;
}

.paymentCardTitle {
  margin-top: 1rem;
  color: #2c2f32;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.paymentCardAmountContainer {
  display: flex;
  flex-direction: column;
  width: 15rem;
}

.paymentCardAmountLineItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
}

.paymentCardAmount {
  margin: 0.75rem 0 0 0;
  text-align: center;
  color: #6b6868;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  width: 100%;
}

.paymentCardPayButton {
  width: 100%;
  margin-top: 2.25rem;
  padding: 0.625rem 0;
  cursor: pointer;
  color: #2c2f32;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 3px 9px rgb(0 0 0 / 0.08);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.125;
}

.paymentCardPayButton:hover {
  transition: background-color 200ms linear;
  color: #fff;
  background-color: #0052b2;
}

/* ==================== GovOs Payment Method Modal ==================== */
.govOsPaymentMethodModalContainer {
  box-sizing: border-box;
  max-height: calc(100vh - 100px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
}

.govOsPaymentMethodModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 6px;
  background: #fff;
}

/* ==================== GovOs Invoice Summary Modal ==================== */
.summaryLinksContainer {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.summaryLinksContainer > a {
  text-decoration: none;
  color: #2c2f32;
}

.summaryLinksContainer > a:hover {
  color: #2c2f32;
  text-decoration: underline;
}
