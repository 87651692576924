@import "styles/shared/colors.css";
@import "styles/shared/breakpoints.css";

.pageWrapper {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin: 0;
  padding: 0;

  border: none;
  background-color: #fff;

  font-family: Inter;
}

@media (max-width: 650px) {
  .pageWrapper {
    background-image: none;
  }
}

.pageWrapper * {
  box-sizing: border-box;
}

.pageWrapper :global .authButton {
  margin: 1.5rem 0;
  padding: 1.24rem 7.36rem;
  color: #051426;
  border: none;
  border-radius: 4px;
  background-color: #ffa41d;
  font-family: Inter;
  font-size: 1.13rem;
  font-weight: 500;
  cursor: pointer;
}

.pageWrapper :global .authButton:disabled {
  color: #676767;
  background-color: rgba(250, 164, 29, 0.7);
  cursor: not-allowed;
}

.pageWrapper :global .inputError {
  width: 100%;
  height: 1rem;
  padding: 0.1rem 0.5rem;

  text-align: left;

  color: #ad1100;

  font-size: 0.7rem;
}

.pageWrapper input[type="email"],
.pageWrapper input[type="text"],
.pageWrapper input[type="url"],
.pageWrapper input[type="password"] {
  width: 100%;
  padding: 1rem;

  border: 2px solid #e2eaec;
  border-radius: 5px;
  outline: none;

  font-size: 1.2rem;
}

.pageWrapper :global input.invalid[type="email"],
.pageWrapper :global input.invalid[type="text"],
.pageWrapper :global input.invalid[type="url"],
.pageWrapper :global input.invalid[type="password"] {
  border-color: #ad1100;
  background-color: rgba(173, 17, 0, 0.05);
}

.pageWrapper h1 {
  margin: 0;

  font-size: 2.13rem;
  line-height: 3rem;
}

.error {
  color: #ad1100;

  font-size: 1rem;
}

.forgotPasswordLabel {
  display: inline-block;

  padding: 0.5rem 0.5rem 0.5rem 0.05rem;

  color: #757575;

  font-size: 1rem;
}

.pageWrapper a,
.pageWrapper a:visited,
.pageWrapper a:focus,
.pageWrapper a:active {
  text-decoration: none;

  color: #0052dd;
}

.container {
  max-width: 500px;
  height: 100%;
  margin: auto;
  margin-top: 8vh;
  padding: 0 10px;

  text-align: center;
}

.logo {
  display: flex;
  position: relative;

  width: 100%;
  margin: 0;
  margin-bottom: 70px;

  text-align: left;

  line-height: 60px;
}

.logo img {
  display: inline-block;

  width: auto;
  height: 58px;
  padding: 0;
}

.logo h2 {
  display: inline-block;

  margin: 0;
  margin-left: 20px;
  padding: 0;

  vertical-align: middle;
  align-self: center;

  line-height: 1.75rem;
}

.subtitle {
  font-size: 1rem;
}

.fieldContainer {
  position: relative;

  margin-bottom: "1rem";

  text-align: left;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 0.5rem;
    width: 100%;
    margin: auto;
    margin-top: 2vh;
    text-align: center;
  }

  .pageWrapper :global .authButton {
    width: 100%;
    padding: 1.24rem 0;
  }

  .fieldContainer {
    margin-bottom: 2.1rem;
  }
}
